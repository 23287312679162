const template = {
  database: 'big_data_db',
  table: {
    status: {
      columns: {
        timestamp: 'int',
        eventdate: 'string',
        thingname: 'string',
        topic: 'string',
        state: 'string',
        current: 'string',
        previous: 'string',
        setTemp: 'float',
        currentTemp: 'float',
        operationMode: 'int',
        connected: 'boolean'
      },
      defaultWhere:
        "topic like '%$aws/things/{}/shadow/name/status/update/documents%'"
    },
    registry: {
      columns: {
        eventtype: 'string',
        eventid: 'string',
        operation: 'string',
        accountid: 'string',
        eventdate: 'string',
        thingid: 'string',
        thingname: 'string',
        versionnumber: 'int',
        thingtypename: 'string',
        billingroupname: 'string',
        attributes: 'string'
      },
      defaultWhere: ''
    },
    event: {
      columns: {
        timestamp: 'int',
        eventdate: 'string',
        thingname: 'string',
        topic: 'string',
        state: 'string',
        current: 'string',
        previous: 'string'
      }
    },
    sleep: {
      columns: {
        timestamp: 'int',
        eventdate: 'string',
        thingname: 'string',
        topic: 'string',
        state: 'string',
        current: 'string',
        previous: 'string'
      }
    }
  }
};
export default template;
