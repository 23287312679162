import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Backdrop, CircularProgress } from '@material-ui/core';
import { userInfoContext } from '../../../../App';
import useAsync from '../../../../asyncNet';
import { deviceGetMateLog } from '../../APIs/mateApis';
import {
  NO_PERMISSION,
  NO_PERMISSION_WARNING,
  READ_PERMISSION
} from '../../../Common/Config/naviwatchConfig';
import DeviceLogToolbar from './DeviceLogToolbar';
import DeviceLogChart from './DeviceLogChart';
import DeviceLogTable from './DeviceLogTable';

const limitNum = 3000;

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const DeviceLog = ({ history }) => {
  const classes = useStyles();

  const authInfo = useContext(userInfoContext);

  const [loadingAnimation, setLoadingAnimation] = useState(false);
  const [noPermissionMsg, setNoPermissionMsg] = useState('');
  const [permission, setPermission] = useState(NO_PERMISSION);
  const [queryDataSum, setQueryDataSum] = useState([]);
  const [lastEventDate, setLastEventDate] = useState('');
  const [recursiveFetch, setRecursiveFetch] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  const [deviceLogQuery, setDeviceLogQuery] = useState({});
  const [deviceLogs, fetchDeviceLogs] = useState(false);

  async function asyncGetDeivceLog() {
    return await deviceGetMateLog(deviceLogQuery);
  }

  const [deviceLogState, tryGetDeviceLog] = useAsync(
    asyncGetDeivceLog,
    [],
    true
  );

  const { loading: deviceLogLoading, data: deviceLogData } = deviceLogState;
  useEffect(() => {
    if (deviceLogs) {
      console.log(loadingAnimation);
      setQueryDataSum([]);
      setLoadingAnimation(true);
      tryGetDeviceLog();
    }
  }, [deviceLogs]);

  useEffect(() => {
    var pageInfo = sessionStorage.getItem('pages');
    if (pageInfo !== 'undefined') {
      var pageInfoObj = JSON.parse(pageInfo);
      var pageArray = pageInfoObj.filter(page =>
        page.name.toLowerCase().includes('device')
      );
      if (pageArray !== undefined && pageArray.length > 0) {
        var page = pageArray[0];
        if (page.permission === undefined) {
          setNoPermissionMsg(NO_PERMISSION_WARNING);
        }
        setPermission(page.permission);
      }
    }
  }, [authInfo.pages]);

  useEffect(() => {
    if (sessionStorage.getItem('naviwatch_permission') !== 'true') {
      history.push('/');
    }
  }, []);

  useEffect(() => {
    if (deviceLogData && !deviceLogLoading) {
      setQueryDataSum([]);
      if ('errorMessage' in deviceLogData) {
        alert('에러 발생');
        setLoadingAnimation(false);
      } else {
        if (deviceLogData.data?.logList.length == 0) {
          alert('데이터가 없습니다.');
          setLoadingAnimation(false);
        }
        console.log('fetch device Logs');
        try {
          let logList = deviceLogData.data?.logList; // 조회된 로그 데이터
          if (logList.length < 1) {
            // 데이터가 없는 경우 중지
            setRecursiveFetch(false);
            setLoopNum(4);
          } else {
            if (logList.length < limitNum) {
              // 데이터가 없는 경우 중지
              setRecursiveFetch(false);
              setLoopNum(4);
            }
            if (logList.length > 0) {
              setLastEventDate(logList[logList.length - 1]['eventdate']); // eventdate의 format이 request의 format과 다름
              let fromTimeDate = new Date(
                logList[logList.length - 1]['eventdate']
              );
              fromTimeDate = fromTimeDate.setHours(fromTimeDate.getHours() + 9);
              setDeviceLogQuery({
                ...deviceLogQuery,
                startTime:
                  new Date(fromTimeDate).toISOString().slice(0, 19) + '+09:00'
              });

              setQueryDataSum([...queryDataSum, ...logList]);
              setLoadingAnimation(false);
            }
            setLoopNum(prev => prev++);
          }
          fetchDeviceLogs(false);
        } catch (e) {
          console.log(e);
          setRecursiveFetch(false);
        }
      }
    } else {
      setRecursiveFetch(false);
      fetchDeviceLogs(false);
    }
  }, [deviceLogLoading, deviceLogData, deviceLogs]);

  useEffect(() => {
    if (loopNum && loopNum < 3) {
      fetchDeviceLogs(true);
      setRecursiveFetch(true);
    }
  }, [loopNum]);

  useEffect(() => {
    if (recursiveFetch && loopNum < 3 && queryDataSum.length > 0) {
      console.log('recursiveFetch');
      try {
        if (deviceLogData) {
          let logList = deviceLogData['logList']; // 조회된 로그 데이터
          if (logList.length < 1) {
            // 데이터가 없는 경우 중지
            setRecursiveFetch(false);
            setLoopNum(4);
          } else {
            if (logList.length < limitNum) {
              // limit보다 적게 들어와서 더는 요청하지 않아도 되는 경우
              setRecursiveFetch(false);
              setLoopNum(4);
            }

            let removeDataNum = 0; // 직전 데이터의 마지막 시간 보다 작은 row의 개수;
            if (lastEventDate.length > 0) {
              for (const row of logList) {
                // 직전 데이터의 가장 마지막 시간 보다 작은 row인 경우 제거
                if (row['eventdate'] <= lastEventDate) {
                  removeDataNum++;
                } else {
                  break;
                }
              }
            }

            if (removeDataNum > 0) {
              // 하나만 같을시
              if (removeDataNum === 1) {
                logList.shift();
              }
              // 여러개의 eventdate가 같은 경우
              else {
                logList.splice(0, removeDataNum);
              }
            }

            if (logList.length > 0) {
              setLastEventDate(logList[logList.length - 1]['eventdate']); // eventdate의 format이 request의 format과 다름
              let fromTimeDate = new Date(
                logList[logList.length - 1]['eventdate']
              );
              fromTimeDate = fromTimeDate.setHours(fromTimeDate.getHours() + 9);
              setDeviceLogQuery({
                ...deviceLogQuery,
                startTime:
                  new Date(fromTimeDate).toISOString().slice(0, 19) + '+09:00'
              });

              if (queryDataSum.length === 0) {
                queryDataSum = logList;
              } else {
                queryDataSum = queryDataSum.concat(logList);
              }
              fetchDeviceLogs(true);
            }
            setLoopNum(loopNum++);
          }
        } else {
          setRecursiveFetch(false);
          fetchDeviceLogs(false);
        }

        fetchDeviceLogs(false);
      } catch (e) {
        console.log(e);
        setRecursiveFetch(false);
      }
    }
  }, [deviceLogData, recursiveFetch, loopNum]);

  const getHistoryData = ({
    from,
    to,
    side,
    columns,
    table,
    wheres,
    deviceId,
    shadow
  }) => {
    let fromTime = from + ':00+09:00';
    let toTime = to + ':00+09:00';
    setLastEventDate('');
    setLoopNum(0);
    setDeviceLogQuery({
      ...deviceLogQuery,
      deviceId,
      table,
      columns: columns.join(', '),
      side,
      shadow,
      order: 'ASC',
      startTime: fromTime,
      endTime: toTime,
      limit: 1000
    });
    if (wheres.length > 0 && wheres[0].value) {
      let where = wheres.reduce((result, current, index) => {
        if (wheres.length - 1 === index) {
          result = result + current['column'] + '=' + current['value'];
        } else {
          result = result + current['column'] + '=' + current['value'] + ',';
        }
        return result;
      }, '');
      setDeviceLogQuery({
        ...deviceLogQuery,
        where
      });
    }
    if (deviceId) {
      fetchDeviceLogs(true);
    }
  };

  useEffect(() => {
    if (deviceLogs) {
      setLoadingAnimation(true);
      setQueryDataSum([]);
    }
  }, [deviceLogs]);

  return (
    <div>
      {permission >= READ_PERMISSION ? (
        <div className={classes.root}>
          <Backdrop className={classes.backdrop} open={loadingAnimation}>
            <CircularProgress color={'primary'} />
          </Backdrop>
          <DeviceLogToolbar getHistoryData={getHistoryData} />
          {queryDataSum.length > 0 && (
            <>
              <DeviceLogTable formatTableData={queryDataSum} />
              {deviceLogQuery &&
                deviceLogQuery?.columns
                  ?.replaceAll(' ', '')
                  .split(',')
                  .filter(x =>
                    [
                      'setTemp',
                      'currentTemp',
                      'operationMode',
                      'connected'
                    ].includes(x)
                  ).length > 0 && <DeviceLogChart chartData={queryDataSum} />}
            </>
          )}
        </div>
      ) : (
        <Typography className={classes.root}>{noPermissionMsg}</Typography>
      )}
    </div>
  );
};

export default DeviceLog;
