import React, { useState, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { CSVLink } from 'react-csv';
import { Button, makeStyles, TextField } from '@material-ui/core';
import moment from 'moment';
import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  FormControl,
  InputLabel,
  Checkbox,
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Grid
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

const TOPIC_LIST = [
  'update',
  'update/documents',
  'update/accepted',
  'update/delta',
  'update/rejected'
];

const DeviceLogTable = props => {
  const { className, formatTableData } = props;

  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [selectedDevices] = useState([]);
  const [columns, setColumns] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [csvHeader, setCsvHeader] = useState([]);

  const [topicSelected, setTopicSelected] = useState(TOPIC_LIST);
  const isAllSelected = useMemo(() => {
    return (
      topicSelected.length > 0 && topicSelected.length === TOPIC_LIST.length
    );
  }, [topicSelected]);

  const [movePageNum, setMovePageNum] = useState(0);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center'
    }
  };

  const removeNullValues = obj => {
    if (Array.isArray(obj)) {
      return obj.map(removeNullValues).filter(item => item !== null);
    } else if (typeof obj === 'object' && obj !== null) {
      return Object.fromEntries(
        Object.entries(obj)
          .map(([key, value]) => [key, removeNullValues(value)])
          .filter(([_, value]) => value !== null)
      );
    }
    return obj;
  };

  const convertString = str => {
    let strToJson = JSON.parse(
      str
        .replace(/=/g, ':') // '='을 ':'로 변경
        .replace(/([a-zA-Z0-9]+):([a-zA-Z0-9_]+)/g, '"$1":"$2"') // 속성명을 문자열로 변환
        .replace(/([a-zA-Z0-9]+):{/g, '"$1":{')
        .replace(/"null"/g, 'null') // "null"을 JavaScript의 null로 유지
        .replace(/"true"/g, 'true') // "true"를 JavaScript true로 유지
        .replace(/"false"/g, 'false') // "false"를 JavaScript false로 유지
        .replace(/:"([0-9]+)"/g, ':$1')
    );
    let removeNullJson = removeNullValues(strToJson);
    return JSON.stringify(removeNullJson, null, 2);
  };

  const deviceLogData = useMemo(() => {
    if (!formatTableData || formatTableData.length == 0) return [];
    return formatTableData.reduce((data, current) => {
      let columns = Object.keys(current);
      let objectData = {};
      if (!topicSelected.includes(current['topic'].split('status/')[1])) {
        return data;
      }
      columns.forEach(column => {
        typeof current[column] == 'boolean'
          ? (objectData[column] = current[column].toString())
          : ['state', 'current', 'previous', 'attributes'].includes(column) &&
            typeof current[column] == 'string'
          ? (objectData[column] = convertString(current[column]))
          : (objectData[column] = current[column]);
      });
      if (objectData) {
        data.push(objectData);
      }
      return data;
    }, []);
  }, [
    formatTableData,
    formatTableData.length,
    topicSelected,
    topicSelected.length
  ]);

  useEffect(() => {
    if (deviceLogData.length > 0) {
      setPage(0);
      setMovePageNum(1);
      const columnsArr = [];
      for (const objKey in deviceLogData[0]) {
        columnsArr.push(objKey);
      }
      setColumns(columnsArr);
    }
  }, [deviceLogData, deviceLogData.length]);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const downLoadCSV = () => {
    const csv_array = [];
    const csv_header = [];
    try {
      columns.forEach(columnInfoItem => {
        csv_header.push({
          label: columnInfoItem,
          key: columnInfoItem
        });
      });

      deviceLogData.forEach(data => {
        csv_array.push(data);
      });

      setCsvHeader(csv_header);
      setCsvData(csv_array);
    } catch (e) {
      alert('Fail csv parsing\n' + e);
    }
  };
  const handleBoxChange = event => {
    const value = event.target.value;

    if (value[value.length - 1] === 'all') {
      setTopicSelected(
        topicSelected.length === TOPIC_LIST.length ? [] : TOPIC_LIST
      );
    } else {
      const columnsArr = value.filter(item => {
        return item;
      });
      setTopicSelected(columnsArr);
    }
  };

  const multiSelectBox = () => {
    return (
      <FormControl className={classes.formControl}>
        <InputLabel className={classes.labelText} id="mutiple-select-label">
          토픽 선택
        </InputLabel>
        <Select
          labelId="mutiple-select-label"
          multiple
          value={topicSelected}
          onChange={handleBoxChange}
          renderValue={selected => selected.join(', ')}
          MenuProps={MenuProps}>
          <MenuItem
            selected={false}
            value="all"
            classes={{
              root: isAllSelected ? classes.selectedAll : ''
            }}>
            <ListItemIcon>
              <Checkbox
                classes={{ indeterminate: classes.indeterminateColor }}
                checked={isAllSelected}
                indeterminate={
                  topicSelected.length > 0 &&
                  topicSelected.length < TOPIC_LIST.length
                }
              />
            </ListItemIcon>
            <ListItemText
              disabled={true}
              inset={false}
              disableTypography={false}
              classes={{ primary: classes.selectAllText }}
              primary={<p>전체 선택</p>}
            />
          </MenuItem>

          {TOPIC_LIST.map(option => (
            <MenuItem key={option} value={option}>
              <ListItemIcon>
                <Checkbox checked={topicSelected.indexOf(option) > -1} />
              </ListItemIcon>
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  return (
    columns.length > 0 && (
      <div style={{ marginTop: 20 }}>
        <Card className={clsx(classes.root, className)}>
          {columns.length > 0 &&
            columns.filter(column =>
              ['state', 'previous', 'current'].includes(column)
            ).length > 0 && (
              <CardContent>
                <Grid item md={12} xs={12}>
                  {multiSelectBox()}
                </Grid>
              </CardContent>
            )}
          <Divider />
          <CardContent className={classes.content}>
            <PerfectScrollbar>
              <div className={classes.inner}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {columns.map((colItem, colIndex) => {
                        return (
                          <TableCell key={colIndex} align={'center'}>
                            <span>
                              <b>{colItem}</b>
                            </span>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {deviceLogData.length > 0 ? (
                      deviceLogData
                        .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                        .map((rowsItem, rowsIndex) => {
                          return (
                            <TableRow
                              className={classes.tableRow}
                              hover
                              key={rowsIndex}
                              selected={
                                selectedDevices.indexOf(rowsIndex) !== -1
                              }>
                              {Object.keys(rowsItem).map(column => {
                                return (
                                  <TableCell key={column} align={'left'}>
                                    <pre>{rowsItem[column]}</pre>
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })
                    ) : (
                      <div />
                    )}
                  </TableBody>
                </Table>
              </div>
            </PerfectScrollbar>
          </CardContent>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
            <Button
              style={{
                marginLeft: 10
              }}
              color="primary"
              variant="contained">
              <CSVLink
                headers={csvHeader}
                data={csvData}
                onClick={() => downLoadCSV()}
                filename={'chartData.csv'}
                target="_blank">
                <p style={{ color: 'white' }}>Export CSV</p>
              </CSVLink>
            </Button>
            <CardActions className={classes.actions}>
              <TextField
                margin="dense"
                name="modePage"
                onChange={e => {
                  setMovePageNum(e.target.value);
                }}
                value={movePageNum}
                variant="outlined"
              />
              <Button
                style={{
                  marginLeft: 10
                }}
                onClick={() => {
                  const pageNum = Number(movePageNum) - 1;
                  if (
                    pageNum >= 0 &&
                    pageNum * rowsPerPage < deviceLogData.length
                  ) {
                    setPage(pageNum);
                  }
                }}
                color="primary"
                variant="contained">
                Move page
              </Button>
              {deviceLogData.length > 0 && (
                <TablePagination
                  component="div"
                  count={deviceLogData.length}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                />
              )}
            </CardActions>
          </div>
        </Card>
      </div>
    )
  );
};

DeviceLogTable.propTypes = {
  className: PropTypes.string,
  resultSet: PropTypes.object
};

export default DeviceLogTable;
