import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import useAsync from '../../../../../asyncNet';
import {
  Backdrop,
  CircularProgress,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Grid,
  DialogTitle,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl
} from '@material-ui/core';
import {
  deviceGetAirReport,
  deviceGetAliveReport,
  deviceGetKitchenplusReport,
  deviceGetStatusReport,
  deviceGetUserInteractionReport,
  deviceGetReport
} from '../../../APIs/aironeApis';

const STRING_AIR_SENSOR = '공기질 센서';
const STRING_USER_INTERACTION = '사용자 제어';
const STRING_ALIVE = '얼라이브';
const STRING_STATUS = '디바이스 상태';
const STRING_KITCHENPLUS = '키친플러스';
const STRING_SEARCH_DATE = '조회날짜';
const STRING_SEARCH = '검색';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  title: {
    marginTop: theme.spacing(4)
  },
  item: {
    height: '10px',
    alignSelf: 'center'
  },
  body: {
    marginLeft: theme.spacing(2)
  }
}));

const ReportDialog = props => {
  const { open, handleClose, device, deviceInfo } = props;
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [reportType, setReportType] = useState(STRING_AIR_SENSOR);
  const [values, setValues] = useState({ device: '', from: '', to: '' });
  const [ReportDownloadUrl, setReportDownloadUrl] = useState('');

  const reportTypeChange = event => {
    setReportType(event.target.value);
  };

  const [report, fetchReport] = useAsync(
    () => {
      console.log('fetchReport');
      console.log('reportType: ' + reportType);
      setLoading(true);
      let type = '';
      switch (reportType) {
        case STRING_AIR_SENSOR:
          type = 'air_sensor';
        case STRING_USER_INTERACTION:
          type = 'user_interaction';
        case STRING_ALIVE:
          type = 'alive';
        case STRING_STATUS:
          type = 'status';
        case STRING_KITCHENPLUS:
          type = 'kitchen_plus';
        default:
          type = 'air_sensor';
      }
      return deviceGetReport(
        device,
        deviceInfo.data.modelCode,
        values.from,
        values.to,
        type
      );
    },
    [],
    true
  );

  useEffect(() => {
    if (report.data !== null && report.data !== undefined) {
      console.log('stateReport: ' + report.data['downloadUrl']);
      setReportDownloadUrl(report.data['downloadUrl']);
      setLoading(false);
    }
  }, [report]);

  const cleanSearchInput = () => {
    setValues({ device: '', from: '', to: '' });
  };

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleSearch = () => {
    if (device !== '' && values.from !== '' && values.to !== '') {
      fetchReport();
    }
  };

  const downloadFile = () => {
    if (ReportDownloadUrl !== '') {
      window.open(ReportDownloadUrl);
    }
  };

  return (
    <div>
      <Dialog
        fullWidth={false}
        maxWidth={'md'}
        open={open}
        onClose={handleClose}
        aria-labelledby="dialog-report">
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color={'primary'} />
        </Backdrop>
        <DialogContent>
          <DialogTitle id="dialog-report"> Report </DialogTitle>
          <FormControl>
            <RadioGroup
              row
              aria-label="ReportType"
              name="ReportType"
              defaultValue={STRING_AIR_SENSOR}
              onChange={reportTypeChange}>
              <FormControlLabel
                value={STRING_AIR_SENSOR}
                control={<Radio />}
                label={STRING_AIR_SENSOR}
              />
              <FormControlLabel
                value={STRING_USER_INTERACTION}
                control={<Radio />}
                label={STRING_USER_INTERACTION}
              />
              <FormControlLabel
                value={STRING_ALIVE}
                control={<Radio />}
                label={STRING_ALIVE}
              />
              <FormControlLabel
                value={STRING_STATUS}
                control={<Radio />}
                label={STRING_STATUS}
              />
              <FormControlLabel
                value={STRING_KITCHENPLUS}
                control={<Radio />}
                label={STRING_KITCHENPLUS}
              />
            </RadioGroup>
          </FormControl>
          <DialogTitle className={classes.title}>
            <b> {STRING_SEARCH_DATE} </b>
          </DialogTitle>
          <Grid container direction="row" className={classes.body}>
            <Grid item xs={4}>
              <TextField
                className={classes.item}
                id="from"
                name="from"
                type="date"
                value={values.from}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={1}>
              <Typography className={classes.item}> ~ </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                className={classes.item}
                id="to"
                name="to"
                type="date"
                value={values.to}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleSearch}
                disabled={values.from == '' || values.to == ''}>
                {' '}
                {STRING_SEARCH}{' '}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <TextField
            color="primary"
            onClick={downloadFile}
            margin="dense"
            fullWidth
            disabled={true}
            value={ReportDownloadUrl}></TextField>
          <Button color="primary" variant="contained" onClick={handleClose}>
            {' '}
            Close{' '}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ReportDialog.propTypes = {
  className: PropTypes.string
};

export default ReportDialog;
